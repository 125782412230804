<template>
  <div>
    <div class="banner" v-if="current===0">
      <div class="title">
        <div>投诉申诉绿色通道</div>
        <div class="bottom-line"></div>
      </div>
      <div class="flex-center" style="font-size: 20px;font-weight: bold">
        【投诉须知】
      </div>
      <div class="content" v-html="webData.complaintNotice">
      </div>
      <div class="privacy">
        <router-link to="/privacy" class="privacy" target="_blank">查看《隐私政策》</router-link>
      </div>
      <div>
        <el-checkbox v-model="checked">
          我已阅读投诉须知并同意隐私政策
        </el-checkbox>
      </div>
      <div class="now-click">
        <el-button type="primary" @click="toReplay">我要投诉
        </el-button>
      </div>
    </div>
    <div class="banner" v-if="current===1">
      <div class="title">
        <div>投诉申诉绿色通道</div>
        <div class="bottom-line"></div>
      </div>
      <div style="width: 100%">
        <el-form
          :inline="true"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm">
          <div class="form-title">被投诉企业</div>
          <el-form-item style="width: 45%" label="企业名称" prop="companyName">
            <el-input v-model="ruleForm.companyName"></el-input>
          </el-form-item>
          <el-form-item style="width: 45%" label="认证产品" prop="certifiedProduct">
            <el-input v-model="ruleForm.certifiedProduct"></el-input>
          </el-form-item>
          <el-form-item style="width: 45%" label="所在地区" prop="location">
            <el-cascader
              :options="options"
              v-model="ruleForm.location"
              style="width: 100%"
              clearable
              @change="handleChange"
              placeholder="请选择省市区"
            ></el-cascader>
          </el-form-item>
          <el-form-item style="width: 40%" label="上传佐证图片" prop="supportingImage">
            <el-upload
              class="upload-demo"
              :action="url"
              accept=".png,.jpg,.gif"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="uploadChange"
              :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过20MB</div>
            </el-upload>
          </el-form-item>
          <div class="form-title">投诉人</div>
          <el-form-item style="width: 45%" label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item style="width: 45%" label="联系电话" prop="contactNumber">
            <el-input v-model="ruleForm.contactNumber"></el-input>
          </el-form-item>
          <el-form-item style="width: 45%" label="通讯地址" prop="mailingAddress">
            <el-input v-model="ruleForm.mailingAddress"></el-input>
          </el-form-item>
          <el-form-item style="width: 45%" label="联系邮箱" prop="contactEmail">
            <el-input v-model="ruleForm.contactEmail"></el-input>
          </el-form-item>
          <el-form-item style="width: 90%" label="投诉内容" prop="complaintContent">
            <el-input type="textarea" :rows="5" v-model="ruleForm.complaintContent"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="now-click">
        <el-button type="primary" @click="toSubmit">提交
        </el-button>
      </div>
    </div>
    <el-dialog
      title="请完成校验"
      :visible.sync="showCode"
      width="30%">
      <slider-range ref="slider" @success="handleSuccess"/>
      <span slot="footer" class="dialog-footer">
    <el-button @click="showCode = false">取 消</el-button>
    <el-button :disabled="!isCodeTrue" type="primary" @click="handSendAppeals">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb.vue'
  import { CodeToText, provinceAndCityDataPlus } from 'element-china-area-data'
  import { postAction } from '../../../api/manage'
  import { randomUUID } from '@/utils/utils'
  import SliderRange from './SliderRange'
  import { getWebsiteInfo } from '@api/websiteInfo'

  export default {
    name: 'AboutUs',
    components: { SliderRange, Breadcurmb },

    data() {
      return {
        url: `${process.env.VUE_APP_API_URL}/gbacpc/dms/complaintappeal/upload`,
        current: 0,
        areaValue: '',
        checked: true,
        showCode: false,
        isCodeTrue: false,
        options: provinceAndCityDataPlus,
        CodeToText: CodeToText,
        fileList: [],
        webData: {},
        menuList: [
          {
            name: '认证机构详情',
            path: ''
          }
        ],
        ruleForm: {
          name: '',
          companyName: '',
          supportingImage: ''
        },
        rules: {
          companyName: [
            { required: true, message: '请输入企业名称', trigger: 'blur' }
          ],
          certifiedProduct: [
            { required: true, message: '请输入认证产品名称', trigger: 'blur' }
          ],
          supportingImage: [
            { required: true, message: '请上传佐证图片', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入投诉人姓名', trigger: 'blur' }
          ],
          contactNumber: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的联系电话' }
          ],
          mailingAddress: [
            { required: true, message: '请输入通讯地址', trigger: 'blur' }
          ],
          contactEmail: [
            { required: true, message: '请输入联系邮箱', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ],
          complaintContent: [
            { required: true, message: '请输入投诉内容', trigger: 'blur' }
          ]
        }
      }
    },
    mounted() {
    },
    created() {
      this.loadWebsiteData()
    },
    methods: {
      loadWebsiteData() {
        let data = {}
        getWebsiteInfo().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          data = res.result[0]
        }).finally(() => {
          // 将数据存入vuex中
          this.$store.commit('WEB_DATA', data)
          this.webData = this.$store.state.webData
        })
      },
      handleRemove(file, fileList) {
        this.ruleForm.supportingImage = fileList.map(item => item.response.result).join(',')
      },
      uploadChange(response, file, fileList) {
        if (file.status === 'success') {
          let url = fileList.map(item => item.response.result).join(',')
          this.$set(this.ruleForm, 'supportingImage', url)
          console.log(this.ruleForm.supportingImage)
        }
      },
      handlePreview(file) {
        window.open(file.url)
      },
      toReplay() {
        if (this.checked) {
          this.current = 1
          this.ruleForm = {}
          // 滚动到顶部
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        } else {
          this.$message.warning('请先阅读投诉须知并同意隐私政策')
        }
      },
      toSubmit() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 校验成功，验证人机校验
            this.showCode = true
            this.$refs.slider.rangeStatus = ''
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handleSuccess() {
        this.isCodeTrue = true
      },
      handSendAppeals() {
        let params = {
          ...this.ruleForm,
          location: this.areaValue,
          fromActionToken: randomUUID()
        }
        postAction('/gbacpc/dms/complaintappeal/submit', params).then(res => {
          if (res.success) {
            this.$message.success('你的投诉/申诉已提交至平台，我们会尽快处理')
            this.current = 0
          } else {
            this.$message.error(res.message)
          }
        }).finally(() => {
          this.showCode = false
        })
      },
      handleChange(value) {
        console.log(value)// 打印出城市code值
        this.getCodeToText(value) // 转换城市名称方法
      },
      // 转换城市名称方法
      getCodeToText(val) {
        let cityName = ''
        switch (val.length) {
        case 1:
          cityName += CodeToText[val[0]]
          break
        case 2:
          cityName += CodeToText[val[0]] + '/' + CodeToText[val[1]]
          break
        case 3:
          cityName +=
            CodeToText[val[0]] +
            CodeToText[val[1]] +
            CodeToText[val[2]]
          break
        default:
          break
        }
        // 剔除字符串中包含“全部”的部分
        cityName = cityName.replace('全部', '')
        console.log(cityName)
        this.areaValue = cityName
      }
    }
  }
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .banner {
    width: 1000px;
    height: 100%;
    margin: 85px auto 40px;

    .title {
      padding: 33px 0;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    width: 100%;
    height: 100%;
    margin: 85px auto 40px;
    padding: 0 15px;

    .title {
      padding: 33px 0;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }
  }
}

.content {
  font-size: 16px;
  text-indent: 2em;
  text-align: justify;
  line-height: 30px;
  margin-top: 20px;
}

.contact {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 30px;
  margin-bottom: 40px;
}

.privacy {
  color: @primary-color;
  margin: 20px 0;
  cursor: pointer;
}

.now-click {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.form-title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto 10px;
}

/deep/ .el-button--primary {
  background-color: @primary-color;
  border-color: @primary-color;
  color: #ffffff;

  &:hover {
    opacity: 0.85;
  }
}

/deep/ .el-button--primary.is-disabled:hover {
  background-color: @primary-color;
}

/deep/ .el-form--inline .el-form-item__content {
  width: 100%;
}
</style>
